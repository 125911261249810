import React, { useMemo, useRef, useEffect, useState } from 'react';
import { parsePreview } from './parser';
import { ImporterFrame } from './ImporterFrame';
import { FormatRawPreview } from './FormatRawPreview';
import { FormatDataRowPreview } from './FormatDataRowPreview';
import { FormatErrorMessage } from './FormatErrorMessage';
import './FormatPreview.scss';
export const FormatPreview = ({ customConfig, file, assumeNoHeaders, currentPreview, onAccept, onCancel }) => {
    const [preview, setPreview] = useState(() => currentPreview && Object.assign({ parseError: undefined }, currentPreview));
    // wrap in ref to avoid triggering effect
    const customConfigRef = useRef(customConfig);
    customConfigRef.current = customConfig;
    const assumeNoHeadersRef = useRef(assumeNoHeaders);
    assumeNoHeadersRef.current = assumeNoHeaders;
    // perform async preview parse
    const asyncLockRef = useRef(0);
    useEffect(() => {
        const oplock = asyncLockRef.current;
        parsePreview(file, customConfigRef.current).then((results) => {
            // ignore if stale
            if (oplock !== asyncLockRef.current) {
                return;
            }
            if (results.parseError) {
                setPreview(results);
            }
            else {
                // pre-fill headers flag (only possible with >1 lines)
                const hasHeaders = !assumeNoHeadersRef.current && !results.isSingleLine;
                setPreview(Object.assign(Object.assign({}, results), { hasHeaders }));
            }
        });
        return () => {
            // invalidate current oplock on change or unmount
            asyncLockRef.current += 1;
        };
    }, [file]);
    const report = useMemo(() => {
        if (!preview) {
            return null;
        }
        if (preview.parseError) {
            return (React.createElement("div", { className: "CSVImporter_FormatPreview__mainResultBlock" },
                React.createElement(FormatErrorMessage, { onCancelClick: onCancel },
                    "Import error: ",
                    React.createElement("b", null, preview.parseError.message))));
        }
        return (React.createElement("div", { className: "CSVImporter_FormatPreview__mainResultBlock" },
            React.createElement("div", { className: "CSVImporter_FormatPreview__header" }, "Raw File Contents"),
            React.createElement(FormatRawPreview, { chunk: preview.firstChunk, warning: preview.parseWarning, onCancelClick: onCancel }),
            preview.parseWarning ? null : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "CSVImporter_FormatPreview__header" },
                    "Preview Import",
                    !preview.isSingleLine && ( // hide setting if only one line anyway
                    React.createElement("label", { className: "CSVImporter_FormatPreview__headerToggle" },
                        React.createElement("input", { type: "checkbox", checked: preview.hasHeaders, onChange: () => {
                                setPreview((prev) => prev && !prev.parseError // appease type safety
                                    ? Object.assign(Object.assign({}, prev), { hasHeaders: !prev.hasHeaders }) : prev);
                            } }),
                        React.createElement("span", null, "Data has headers")))),
                React.createElement(FormatDataRowPreview, { hasHeaders: preview.hasHeaders, rows: preview.firstRows })))));
    }, [preview, onCancel]);
    return (React.createElement(ImporterFrame, { fileName: file.name, nextDisabled: !preview || !!preview.parseError || !!preview.parseWarning, onNext: () => {
            if (!preview || preview.parseError) {
                throw new Error('unexpected missing preview info');
            }
            onAccept(preview);
        }, onCancel: onCancel }, report || (React.createElement("div", { className: "CSVImporter_FormatPreview__mainPendingBlock" }, "Loading preview..."))));
};
